import PhoneField from 'components/Fields/Phone'
import EmailField from 'components/Fields/Email'
import TextField from 'components/Fields/Text'


const B2BFields = ({ control }) => (
  <div className="form-landing__wrapper">
    <div className="form-landing__block">
      <EmailField
        control={control}
        name="email"
        label="Email corporativo"
        placeholder="seuemail@empresa.com.br"
        className="form-landing__input"
      />
    </div>

    <div className="form-landing__block">
      <PhoneField
        control={control}
        name="phone"
        label="Telefone"
        placeholder="(00) 0 0000 0000"
        className="form-landing__input"
      />
    </div>

    <div className="form-landing__block">
      <TextField
        control={control}
        name="company_name"
        label="Empresa"
        placeholder="Nome da empresa"
        className="form-landing__input"
      />
    </div>
  </div>
)


export default B2BFields