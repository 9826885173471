import compose from 'utils/composeEnhancer'
import handleFeedbackStore from 'hooks/handleFeedbackStore'
import formValidation from './formValidation'
import toggleSidebarStore from 'hooks/toggleSidebarStore'

export default compose(
  toggleSidebarStore,
  formValidation,
  handleFeedbackStore,
)
