const Email = ({ label, name, className, control, placeholder }) => {
	const { register, errorsRef } = control
	const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  const errorList = {
    required: 'Este campo é obrigatório.',
		pattern: 'Email inválido'
  }

  const errorType = errorsRef.current?.[name]?.type

  return (
    <>
      <div className="field-box">
        <input
          name={name}
          className={`field-box__input ${className}`}
					placeholder={placeholder}
					ref={register({ 
						required: true,
						pattern
					})}
        />

				 { errorType && <span className="field-box__error">{errorList[errorType]}</span>}
      </div>
      <label className="form-landing__label">{label}</label>
    </>
  )
}

export default Email 
