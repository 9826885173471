const RadioInput = ({ label, name, className, control, value }) => {
  const { register, errorsRef } = control

  const errorList = {
    required: 'Este campo é obrigatório.'
  }

  const errorType = errorsRef.current?.[name]?.type
  let id = `${name}-${value}`

  return (
    <>
      <div className="field-box">
        <input
          type="radio"
          name={name}
          className={`field-box__radio ${className}`}
          id={id}
          value={value}
          ref={register({
            required: true
          })}
        />

        <label className="radio-label" htmlFor={id}>
          {label}
        </label>

        {errorType && <span className="field-box__error">{errorList[errorType]}</span>}
      </div>
    </>
  )
}

export default RadioInput 
