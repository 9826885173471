export const isDDDValid = (value = '') => {
	if (!value) return true

	let onlyNums = String(value.match(/\d+/g) || '')
	let phoneCode =
		onlyNums && typeof onlyNums === 'string'
			? Number(onlyNums.substring(0, 2))
			: ''
	const validPhoneCodeRegex = new RegExp(
		'(11|12|13|14|15|16|17|18|19|21|22|24|27|28|31|32|33|34|35|37|38|41|42|43|44|45|46|47|48|49|51|53|54|55|61|62|63|64|65|66|67|68|69|71|73|74|75|77|79|81|82|83|84|85|86|87|88|89|91|92|93|94|95|96|97|98|99)',
		'gi'
	)

	return validPhoneCodeRegex.test(phoneCode)
}

export const isPhoneValid = (value = '', minLength, maxLength, type) => {
	if (value === null) value = ''
	let onlyNums = value.match(/\d+/g)

	if (!onlyNums) {
		return false
	} else {
		onlyNums = onlyNums.join('')
	}

	if (
		/(\d)\1{7,}/.test(onlyNums.substr(2)) ||
		/^(.)\1+$/.test(onlyNums.substring(2)) ||
		(/^0/.test(onlyNums) ||
			(type === 'cellphone' && !/^\d{2}[4-9]\d{8}$/.test(onlyNums)) ||
			(onlyNums.length < minLength || onlyNums.length > maxLength))
	) {
		return false
	} else {
		return true
	}
}
