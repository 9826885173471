import Form from 'components/FormAbout'
import enhancer from './hooks'

const SectionForm = ({ customClass, feedbackIsTrue }) => {
  return (
    <section className={`section-form ${customClass ? `section-form--${customClass}` : ''}`}>
      <div className="container section-form__content">
        <div className="section-form__form">
          <Form customTitle="Seu futuro não pode esperar" />
        </div>
      </div>
    </section>
  )
}

export default enhancer(SectionForm)
