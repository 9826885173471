import { Fragment } from 'react'
import Icon from 'rv-components-onze/src/Icon'

import enhancer from './hooks'

const Sidebar = ({ children, sidebarIsOpened, onClickSidebar, onClickFeedback }) => {
  const onClickClose = () => {
    onClickSidebar()
    onClickFeedback()
  }
  return (
    <Fragment>
      <div
        className={`sidebar-overlay ${sidebarIsOpened ? 'sidebar-overlay--active' : ''}`}
        onClick={onClickClose}
      />
      <div className={`sidebar-landing ${sidebarIsOpened ? 'sidebar-landing--active' : ''}`}>
        <Icon iconId="close" onClick={onClickClose} />
        {children}
      </div>
    </Fragment>
  )
}

export default enhancer(Sidebar)
