import { useSelector, useDispatch } from 'react-redux'
import { toggleFeedbackForm, toggleSidebar } from 'ducks/ui'
import { softlead } from 'services'
import { useState } from 'react'
import segmentDispatcher from 'utils/segment/dispatcher'
import { withReCaptcha } from 'utils/reCaptcha'
import { navigate } from '@reach/router'
import Routes from 'Routes'

const { success } = Routes

const handleFeedbackStore = ({ onClickSidebar, businessType }) => {
  const [isLoading, setLoading] = useState(false)
  const feedbackIsTrue = useSelector(state => state.ui.feedbackFormIsTrue)
  const dispatch = useDispatch()

  const onClickFeedback = () => {
    dispatch(toggleFeedbackForm(false))
  }

  const onSubmit = leadFormType => data => {
    setLoading(true)

    withReCaptcha(_ => {
      softlead
        .post(data)
        .then(() => {
          segmentDispatcher(leadFormType)
          dispatch(toggleSidebar(false))
          setLoading(false)
          navigate(`${success.path}?message=${businessType}`)
        })
        .catch(e => {
          console.log('Error: ', e)
          setLoading(false)
        })
    })
  }

  return {
    onClickFeedback,
    feedbackIsTrue,
    isLoading,
    onSubmit,
  }
}

export default handleFeedbackStore
