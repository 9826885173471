const Text = ({
  label,
  name,
  className,
  control,
  placeholder,
  validators = {},
  errors,
}) => {
  const { register, errorsRef,setValue, triggerValidation } = control
  const errorList = {
    required: 'Este campo é obrigatório.',
    ...errors
  }

  const errorType = errorsRef.current?.[name]?.type
  return (
    <>
      <div className="field-box">
        <input
          name={name}
          className={`field-box__input ${className}`}
          placeholder={placeholder}
          onChange={e => {
            setValue(name, e.target.value)
						triggerValidation(name)
          }}
          ref={register({
            required: true,
            validate: {
              ...validators,
            },
          })}
        />

        {errorType && (
          <span className="field-box__error">{errorList[errorType]}</span>
        )}
      </div>
      <label className="form-landing__label">{label}</label>
    </>
  )
}

export default Text
