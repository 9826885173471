import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'

const formValidation = () => {
  const { handleSubmit, control, watch, formState: { isValid } } = useForm({ mode: "onChange" })
  const watchBusinessType = watch(["type"])

  const [businessType, setBusinessType] = useState(null)

  useEffect(() => {
    setBusinessType(watchBusinessType.type)
  }, [watchBusinessType.type])

  return {
    handleSubmit,
    control,
    isValid,
    businessType
  }
}


export default formValidation