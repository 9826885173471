import { useSelector, useDispatch } from 'react-redux'
import { toggleSidebar } from 'ducks/ui'

const handleSidebarStore = () => {
  const sidebarIsOpened = useSelector(state => state.ui.sidebarIsOpened)
  const dispatch = useDispatch()
  const onClickSidebar = () => {
    dispatch(toggleSidebar(!sidebarIsOpened))
  }

  return { sidebarIsOpened, onClickSidebar }
}

export default handleSidebarStore
