import { useSelector } from 'react-redux'

const handleFeedbackStore = () => {
  const feedbackIsTrue = useSelector(state => state.ui.feedbackFormIsTrue)
  return {
    feedbackIsTrue,
  }
}

export default handleFeedbackStore
