import axios from 'axios'
import { API_ROOT, SOFT_LEAD } from 'gatsby-env-variables'

import { apiFormatter } from 'utils/helpers'

function post({ phone, email, has_private_pension, ...rest }) {

	const phoneNumber = phone.replace(/\D/gm, '')
	const privatePension = has_private_pension ? JSON.parse(has_private_pension.toLowerCase()) : undefined
	const payload = {
		email: email.toLowerCase(),
		phone: apiFormatter(`55${phoneNumber}`),
		source: window.location.href,
		has_private_pension: privatePension,
		...rest
	}

	return axios({
		method: 'POST',
		baseURL: API_ROOT,
		url: SOFT_LEAD,
		data: payload,
		headers: {
			'Content-Type': 'application/json'
		}
	})

}

export default {
	post,
}
