import { Fragment } from 'react'
import { SwitchTransition, CSSTransition } from 'react-transition-group'
import Button from 'rv-components-onze/src/Button'
import Typography from 'rv-components-onze/src/Typography'
import Loader from 'components/Loader'
import TextField from 'components/Fields/Text'
import RadioField from 'components/Fields/RadioField'
import Condition from 'components/Condition'
import B2BFields from './components/B2BFields'
import B2CFields from './components/B2CFields'

import enhancer from './hooks'
import Routes from 'Routes'

const { privacyPolice } = Routes

const Form = ({
  onSubmit,
  isLoading,
  btnText = 'Enviar',
  btnIconId = 'right-arrow',
  customTitle,
  handleSubmit,
  businessType,
  control,
  isValid,
}) => {
  const businessTypeMapper = {
    b2b: <B2BFields control={control} />,
    b2c: <B2CFields control={control} />,
  }
  const leadFormTypeMapper = {
    b2b: 'corporateLeadCompleted',
    b2c: 'individualLeadCompleted',
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <Fragment>
      <Condition condition={customTitle}>
        <p className="form-hero__form-title headline5">{customTitle}</p>
      </Condition>

      <form
        className="form-landing"
        onSubmit={handleSubmit(onSubmit(leadFormTypeMapper[businessType]))}>
        <div className="form-landing__content">
          <div className="form-landing__block">
            <TextField
              control={control}
              name="name"
              label="Nome"
              placeholder="Nome completo"
              className="form-landing__input"
            />
          </div>

          <div className="form-landing__block">
            <RadioField
              control={control}
              name="type"
              value="b2c"
              label="Para mim (pessoa física)"
            />
            <RadioField
              control={control}
              name="type"
              value="b2b"
              label="Meus colaboradores (plano empresa)"
            />
            <Typography
              as="p"
              variant="body1"
              className="form-landing__subtitle">
              Quero a Onze para…
            </Typography>
          </div>

          {businessType && (
            <SwitchTransition mode="out-in">
              <CSSTransition
                key={businessType}
                classNames="form-landing-animate"
                timeout={450}
                unmountOnExit
                mountOnEnter>
                {businessTypeMapper[businessType]}
              </CSSTransition>
            </SwitchTransition>
          )}

          <Button
            label={btnText}
            customClass="conversation-form__submit"
            type="sumbit"
            buttonSize="large"
            iconId={btnIconId}
            iconPosition="right"
            disabled={!isValid}
          />

          <a
            href={privacyPolice.path}
            className="form-landing__btn-link"
            title="Ver políticas de priadidade">
            Ver políticas de privacidade
          </a>
        </div>
      </form>
    </Fragment>
  )
}

export default enhancer(Form)
