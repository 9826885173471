import classNames from 'classnames'

const Loader = ({ inverted = false, fixed = false, large = false }) => {
  const loaderClasses = classNames(
    'loader',
    { 'is--large': large },
    { 'loader--fixed': fixed },
    { 'loader--inverted': inverted },
  )

  return (
    <div className={loaderClasses}>
      <div className="loader__cut">
        <div className="loader__donut" />
      </div>
    </div>
  )
}

export default Loader
