import MaskedInput from 'react-input-mask'
import { isDDDValid, isPhoneValid } from 'utils/validators/phone'
          
const Phone = ({ label, name, className, control, placeholder }) => {
  const { errorsRef, setValue, register, triggerValidation } = control
  const errorList = {
    required: 'Este campo é obrigatório.',
		isDDDValid: 'Digite um DDD válido',
		isPhoneValid: 'Digite um telefone válido',
  }

  const errorType = errorsRef.current?.[name]?.type

  return (
    <>
      <div className="field-box">
        <input
          name={name}
					ref={register({ 
						required: true,
						validate: {
							isDDDValid,
							isPhoneValid: value => isPhoneValid(value, 10, 11, 'phone'),
						}
					})}
          style={{ display: 'none' }}
        />

        <MaskedInput
          type="tel"
          className={`field-box__input ${className}`}
          placeholder={placeholder}
          mask="(99) 99999-9999"
					maskChar={null}
          onChange={e => {
            setValue(name, e.target.value)
						triggerValidation(name)
          }}
        />

        {errorType && (
          <span className="field-box__error">{errorList[errorType]}</span>
        )}
      </div>
      <label className="form-landing__label">{label}</label>
    </>
  )
}

export default Phone
