import R from 'ramda'

export const apiFormatter = R.pipe(
	R.defaultTo(''),
	R.split(''),
	R.filter(value => value.trim() && !isNaN(value)),
	R.join('')
)


export const $ = {
	querySelector(selector) {
		const element = document.querySelector(selector)
		return fromNullable(element)
	},

	querySelectorAll(selector) {
		const elements = document.querySelectorAll(selector)
		return fromNullable(elements).map(Array.from)
	},

	disableElement(elementName) {
		return $.querySelector(elementName).map(disableElement)
	},

	enableElement(elementName) {
		return $.querySelector(elementName).map(enableElement)
	},

	hideField(query) {
		return $.querySelector(query)
			.chain(el => safeProp('parentNode', el))
			.map(parent => (parent.style.display = 'none'))
	},
}