import R from 'ramda'
import { Fragment } from 'react'
import { env, CMS } from 'gatsby-env-variables'
import enhancer from './hooks'

import { loadImage } from 'utils/lazyLoadImages'
import isClient from 'utils/isClient'

const isLocal = env === 'local'
const previewMode = isClient() && /previewMode/.test(window.location.search)

const shouldUseCockpit = R.or(isLocal, previewMode)

const getSourceFromCockpit = (src = '') =>
  shouldUseCockpit
    ? `${CMS.baseUrl}${src}`
    : `/images${src.replace('/storage/uploads', '')}`

const Img = ({
  src,
  alt,
  width,
  isCockpit = false,
  sourceIsRemote,
  async = true,
  height,
  placeholderImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2NgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII',
  lazyImages,
  ...rest
}) => {
  delete rest.dispatch
  let dataSrc = ''
  let ref = lazyImages.fallback ? loadImage : null

  if (sourceIsRemote) dataSrc = src
  else if (isCockpit) dataSrc = getSourceFromCockpit(src)
  else dataSrc = `/images${src}`

  return (
    <img
      // data-lazy-image={async && !isLocal ? 'on' : 'off'}
      src={dataSrc}
      data-src={dataSrc}
      alt={alt}
      ref={ref}
      {...rest}
    />
  )
}

export default enhancer(Img)
