import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import compose from 'utils/composeEnhancer'
import dispatchCustomEvent from 'utils/customEvent'

const useLazyImages = () => {
	const lazyImages = useSelector(store => store.lazyImages)

	useEffect(() => {
		dispatchCustomEvent({ name: 'lazyimage:mounted' })
	}, [])

	return {
		lazyImages
	}
}

export default compose(useLazyImages)
