import Typography from 'rv-components-onze/src/Typography'
import PhoneField from 'components/Fields/Phone'
import EmailField from 'components/Fields/Email'
import RadioField from 'components/Fields/RadioField'


const B2CFields = ({ control }) => {

  return (
    <div className="form-landing__wrapper">
      <div className="form-landing__block">
        <EmailField
          control={control}
          name="email"
          label="Email"
          placeholder="Digite seu melhor email"
          className="form-landing__input"
        />
      </div>

      <div className="form-landing__block">
        <PhoneField
          control={control}
          name="phone"
          label="Telefone"
          placeholder="(00) 0 0000 0000"
          className="form-landing__input"
        />
      </div>

      <div className="form-landing__block">
        <Typography as="p" variant="body1" className="form-landing__subtitle">
          Você tem previdência privada?
        </Typography>
      </div>

      <div className="form-landing__block">
        <RadioField
          control={control}
          name="has_private_pension"
          value={true}
          label="Sim"
        />
      </div>

      <div className="form-landing__block">
        <RadioField
          control={control}
          name="has_private_pension"
          value={false}
          label="Não"
        />
      </div>
    </div>
  )
}


export default B2CFields